<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <path d="M100,0.5c-27.2,0-49.3,22.1-49.3,49.3S72.8,99.1,100,99.1s49.3-22.1,49.3-49.3S127.2,0.5,100,0.5z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M166.7,138.4c-14.7-14.9-34.1-23.1-54.8-23.1H88.1c-20.7,0-40.1,8.2-54.8,23.1     c-14.6,14.8-22.6,34.4-22.6,55.1c0,3.3,2.7,6,6,6h166.8c3.3,0,6-2.7,6-6C189.3,172.8,181.3,153.3,166.7,138.4z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconPeople',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

